import {

  GET_DEPARTMENT,
  GET_DEPARTMENT_SUCCESS,
  GET_DEPARTMENT_ERROR,

  GET_DEPARTMENT_BY_COMPANY,
  GET_DEPARTMENT_BY_COMPANY_SUCCESS,
  GET_DEPARTMENT_BY_COMPANY_ERROR,


  GET_ALL_DEPARTMENT,
	GET_ALL_DEPARTMENT_SUCCESS,
	GET_ALL_DEPARTMENT_ERROR,

  UPDATE_DEPARTMENT,
  UPDATE_DEPARTMENT_SUCCESS,
  UPDATE_DEPARTMENT_ERROR,

  SAVE_DEPARTMENT,
  SAVE_DEPARTMENT_SUCCESS,
  SAVE_DEPARTMENT_ERROR,

  DELETE_DEPARTMENT,
  DELETE_DEPARTMENT_SUCCESS,
  DELETE_DEPARTMENT_ERROR,
  CLEAR_TEAM_SUCCESS,

  GET_FILTERATION_DATA,
  GET_FILTERATION_DATA_SUCCESS,
  GET_FILTERATION_DATA_ERROR,
  
	RESET_FILTERATION_DATA_SUCCESS,
	RESET_FILTERATION_DATA,
  RESET_FILTERATION_DATA_ERROR,
  LOGOUT_USER
} from "../actions";

const INIT_STATE = {
  departments: [],
  department: {},
  secondLoading:false,
  loadingData:{},
  loading: false,
  departmentsLoading: false,
  error: "",
  success: "",
  modalSuccess: "",
  filterData:[],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {


    case GET_ALL_DEPARTMENT:
      return { ...state, departmentsLoading: true, error: "" };
    case GET_ALL_DEPARTMENT_SUCCESS:
      return {
        ...state,
        departments: action.payload,
        error: "",
        departmentsLoading: false,
      };
    case GET_ALL_DEPARTMENT_ERROR:
      return {
        ...state,
        loading: false,
        departments: [],
        error: action.payload.message,
        departmentsLoading: false,
      };
    case GET_DEPARTMENT:
      return { ...state, loading: true,departmentsLoading: true, error: "" };

    case GET_DEPARTMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        department: action.payload,
        error: "",
        departmentsLoading: false,

      };

    case GET_DEPARTMENT_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        departmentsLoading: false,

      };

    case GET_DEPARTMENT_BY_COMPANY:
      return { ...state, loading: true, departmentsLoading: true,error: "" };

    case GET_DEPARTMENT_BY_COMPANY_SUCCESS:
      return {
        ...state,
        loading: false,
        departments: action.payload,
        error: "",
        departmentsLoading: false,

    };

    case GET_DEPARTMENT_BY_COMPANY_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        departmentsLoading: false,

      };

    case SAVE_DEPARTMENT:
      return { ...state, loading: true, error: "", success: "" };

    case SAVE_DEPARTMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        department: action.payload,
        error: "",
        success: true,
      };

    case SAVE_DEPARTMENT_ERROR:
      return {
        ...state,
        loading: false,
        department: {},
        error: action.payload.message,
        success: "",
      };

    case UPDATE_DEPARTMENT:
      return { ...state, loading: true, error: "", success: "" };

    case UPDATE_DEPARTMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        success: true,
      };

    case UPDATE_DEPARTMENT_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        success: "",
      };

    case DELETE_DEPARTMENT:

      return { ...state, secondLoading: true,loadingData:action.payload, error: "" };

    case DELETE_DEPARTMENT_SUCCESS:
      return {
        ...state,
        secondLoading: false,
        departments: state.departments?.filter((el) => {
          return el.id !== action.payload.id
        }),
        error: "",
        loadingData:{}
    };

    case DELETE_DEPARTMENT_ERROR:
      return {
        ...state,
        secondLoading: false,
        // employee: {},
        error: action.payload.message,
        loadingData:{}
      };

    case GET_FILTERATION_DATA:
      return { ...state, loading: true,departmentsLoading: true, error: "" };
      case GET_FILTERATION_DATA_SUCCESS:
        return {
          ...state,
          loading: false,
          filterData: action.payload,
          error: "",
          departmentsLoading: false,
      };
  
      case GET_FILTERATION_DATA_ERROR:
        return {
          ...state,
          loading: false,
          error: action.payload.message,
          departmentsLoading: false,

        };


        case RESET_FILTERATION_DATA:
          return { ...state, loading: true, error: "" };
          case RESET_FILTERATION_DATA_SUCCESS:

            return {
              ...state,
              loading: false,
              filterData: action.payload,
              error: "",
          };
      
          
        
  
    case CLEAR_TEAM_SUCCESS:
      return { ...state, loading: false, error: "", success: "" };

    default:
      return { ...state };
  }
};
