import {
  GET_ALL_WIDGET,
  GET_ALL_WIDGET_SUCCESS,
  GET_ALL_WIDGET_ERROR,
  GET_ALL_WIDGET_BY_COMPANY,
  GET_ALL_WIDGET_BY_COMPANY_SUCCESS,
  GET_ALL_WIDGET_BY_COMPANY_ERROR,
  UPDATE_WIDGET,
  UPDATE_WIDGET_ERROR,
  UPDATE_WIDGET_SUCCESS,
  CREATE_WIDGET,
  CREATE_WIDGET_SUCCESS,
  CREATE_WIDGET_ERROR,
  CLEAR_COMPANY_WIDGETS_SUCCESS
} from "../actions";

const INIT_STATE = {
  profileSettingloader: false,
  // allWidgets:[],
  widgets: [],
  loading:false,
  companyWidgets:[],
  error: "",
  companyWidgetError:"",
  success:false,
};

export default (state = INIT_STATE, action) => {
  // console.log(action)
  switch (action.type) {
    case GET_ALL_WIDGET:
      return { ...state, profileSettingloader: "getAllWidget", companyWidgetError: "" };

    case GET_ALL_WIDGET_SUCCESS:
      return {
        ...state,
        profileSettingloader: false,
        widgets: action.payload,
        error: "",
        companyWidgetError:""
      };
    case GET_ALL_WIDGET_ERROR:
      return {
        ...state,
        profileSettingloader: false,
        companyWidgetError:action.payload.message,

        error: action.payload.message,
      };
      case GET_ALL_WIDGET_BY_COMPANY:
      return { ...state, profileSettingloader: "getAllWidgetByCompany", error: "",companyWidgetError:""  };
      case GET_ALL_WIDGET_BY_COMPANY_SUCCESS:
        return {
          ...state,
          profileSettingloader: false,
          companyWidgets: action.payload,
          error: "",
          companyWidgetError:"" 
        };
      case GET_ALL_WIDGET_BY_COMPANY_ERROR:
        return {
          ...state,
          profileSettingloader: false,
          companyWidgetError:action.payload.message,
          error: action.payload.message,
        };
    case CREATE_WIDGET:
      return { ...state, loading: true, success:false,error: "" ,companyWidgetError:"" };
    case CREATE_WIDGET_SUCCESS:
      return { ...state, loading: false ,success:true, error: "" ,companyWidgetError:"" };

    case CREATE_WIDGET_ERROR:
      return {
        ...state,
        companyWidgetError:action.payload.message,
        error: action.payload.message,
        loading:false,
        success:false,
      };
    case UPDATE_WIDGET:
      return { ...state, loading: true, error: "" ,companyWidgetError:"" };

    case UPDATE_WIDGET_SUCCESS:
      return {
        ...state,
        companyWidgetError:"",
        loading:false,

        // widgetsFormData: [...state.widgetsFormData, action.payload],
      };
    case UPDATE_WIDGET_ERROR:
      return {
        ...state,
        companyWidgetError:action.payload.message,
        error: action.payload.message,
        loading:false,

      };
      case CLEAR_COMPANY_WIDGETS_SUCCESS:
        return { ...state, loading: false, error: "",companyWidgetError: "", success: "",profileSettingloader:false };
    default:
      return { ...state };
  }
};
