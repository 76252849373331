import {
  GET_EXCHANGECONTACT,
  GET_EXCHANGECONTACT_SUCCESS,
  GET_EXCHANGECONTACT_ERROR,
  GET_EXCHANGECONTACT_BY_COMPANY,
  GET_EXCHANGECONTACT_BY_COMPANY_SUCCESS,
  GET_EXCHANGECONTACT_BY_COMPANY_ERROR,
  GET_EXCHANGECONTACT_BY_EMPLOYEE_NAME,
  GET_EXCHANGECONTACT_BY_EMPLOYEE_NAME_SUCCESS,
  GET_EXCHANGECONTACT_BY_EMPLOYEE_NAME_ERROR,
  UPDATE_EXCHANGECONTACT,
  UPDATE_EXCHANGECONTACT_SUCCESS,
  UPDATE_EXCHANGECONTACT_ERROR,
  SAVE_EXCHANGECONTACT,
  SAVE_EXCHANGECONTACT_SUCCESS,
  SAVE_EXCHANGECONTACT_ERROR,
  DELETE_EXCHANGECONTACT,
  DELETE_EXCHANGECONTACT_SUCCESS,
  DELETE_EXCHANGECONTACT_ERROR,
  CLEAR_TEAM_SUCCESS,
  GET_EXCHANGECONTACT_FILTERATION_DATA,
  RESET_FILTERATION_DATA,
  RESET_FILTERATION_DATA_SUCCESS,
  GET_EXCHANGECONTACT_FILTERATION_DATA_SUCCESS,
  GET_EXCHANGECONTACT_FILTERATION_DATA_ERROR,
  LOGOUT_USER,
  CLEAR_EXCHANGE_CONTACT_SUCCESS,
} from "../actions";

const INIT_STATE = {
  exchangeContacts: [],
  exchangeContact: {},
  filterData: [],
  exchangeLoading:false,
  loading: false,
  error: "",
  success: "",
  modalSuccess: "",
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_EXCHANGECONTACT:
      return { ...state ,exchangeLoading:true, error: "" };

    case GET_EXCHANGECONTACT_SUCCESS:
      return {
        ...state,
        exchangeContact: action.payload,
        error: "",
        exchangeLoading:false,

      };

    case GET_EXCHANGECONTACT_ERROR:
      return {
        ...state,
        error: action.payload.message,
        exchangeLoading:false,

      };

    case GET_EXCHANGECONTACT_BY_COMPANY:
      return { ...state,exchangeLoading:true, error: "" };

    case GET_EXCHANGECONTACT_BY_COMPANY_SUCCESS:
      return {
        ...state,
        exchangeContacts: action.payload,
        error: "",
        exchangeLoading:false,

      };

    case GET_EXCHANGECONTACT_BY_COMPANY_ERROR:
      return {
        ...state,
        error: action.payload.message,
        exchangeLoading:false,

      };

    case GET_EXCHANGECONTACT_BY_EMPLOYEE_NAME:
      return { ...state, exchangeLoading:true,error: "" };

    case GET_EXCHANGECONTACT_BY_EMPLOYEE_NAME_SUCCESS:
      return {
        ...state,
        exchangeContacts: action.payload,
        error: "",
        exchangeLoading:false,

      };

    case GET_EXCHANGECONTACT_BY_EMPLOYEE_NAME_ERROR:
      return {
        ...state,
        error: action.payload.message,
        exchangeLoading:false,

      };

    case SAVE_EXCHANGECONTACT:
      return { ...state, loading: true, error: "", success: "" };

    case SAVE_EXCHANGECONTACT_SUCCESS:
      return {
        ...state,
        loading: false,
        exchangeContact: action.payload,
        error: "",
        success: true,
      };

    case SAVE_EXCHANGECONTACT_ERROR:
      return {
        ...state,
        loading: false,
        exchangeContact: {},
        error: action.payload.message,
        success: "",
      };

    case UPDATE_EXCHANGECONTACT:
      return { ...state, loading: true, error: "", success: "" };

    case UPDATE_EXCHANGECONTACT_SUCCESS:
      return {
        ...state,
        loading: false,
        error: "",
        success: true,
      };

    case UPDATE_EXCHANGECONTACT_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        success: "",
      };

    case DELETE_EXCHANGECONTACT:
      return { ...state, loading: true, error: "" };

    case DELETE_EXCHANGECONTACT_SUCCESS:
      return {
        ...state,
        loading: false,
        exchangeContacts: state?.exchangeContacts?.filter((el) => {
          return el.id !== action.payload.id;
        }),
        error: "",
      };

    case DELETE_EXCHANGECONTACT_ERROR:
      return {
        ...state,
        loading: false,
        // employee: {},
        error: action.payload.message,
      };

    case GET_EXCHANGECONTACT_FILTERATION_DATA:
      return { ...state,  exchangeLoading:true,error: "" };
    case GET_EXCHANGECONTACT_FILTERATION_DATA_SUCCESS:

      return {
        ...state,
        error: "",
        exchangeContacts: action.payload,
        success: "",
        exchangeLoading:false,
      };
    case GET_EXCHANGECONTACT_FILTERATION_DATA_ERROR:
      return { ...state, exchangeLoading:false,error: "" };
    case RESET_FILTERATION_DATA:
      return { ...state,exchangeLoading:true, error: "" };
    case RESET_FILTERATION_DATA_SUCCESS:
      return {
        ...state,
        filterData: action.payload,
        error: "",
        exchangeLoading:false,

      };

 
      case CLEAR_EXCHANGE_CONTACT_SUCCESS:
        return { ...state, loading: false, error: "", success: "",exchangeLoading:false };
    default:
      return { ...state };
  }
};
