import { getCurrentUser } from "helpers/Utils";
//add this in reducers

import { REHYDRATE } from 'redux-persist/lib/constants'; 

   
import {
  GET_AUDIT_LOG,
	GET_AUDIT_LOG_SUCCESS,
	GET_AUDIT_LOG_ERROR,

  GET_AUDIT_LOG_BY_COMPANY,
	GET_AUDIT_LOG_BY_COMPANY_SUCCESS,
	GET_AUDIT_LOG_BY_COMPANY_ERROR,

  GET_FILTERATION_DATA_LOG,
	GET_FILTERATION_DATA_LOG_SUCCESS,
	GET_FILTERATION_DATA_LOG_ERROR,
  LOGOUT_USER,

} from "../actions";

const INIT_STATE = {
  auditlog:[],
  filterData:[],
  auditLogError:"",
  auditLogLoader:false,
  loading: false,
  error: "",
  success: false,
  message: "",
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_AUDIT_LOG_BY_COMPANY:
      return { ...state, loading: true, auditLogLoader:true,auditLogError: "" };
      case GET_AUDIT_LOG_BY_COMPANY_SUCCESS:
        return {
          ...state,
          loading: false,
          auditlog: action.payload,
          auditLogError: "",
          auditLogLoader:false,
        };
        case GET_AUDIT_LOG_BY_COMPANY_ERROR:
          return {
            ...state,
            loading: false,
            auditLogError: action.payload.message,
          auditLogLoader:false,

          };
    case GET_AUDIT_LOG:
      return { ...state, loading: true,auditLogLoader:true, error: "" };
    case GET_AUDIT_LOG_SUCCESS:
      return {
        ...state,
        loading: false,
        auditlog: action.payload,
        error: "",
        auditLogLoader:false,

      };
    case GET_AUDIT_LOG_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
        auditLogLoader:false,

      };

      case GET_FILTERATION_DATA_LOG:
        return { ...state, loading: true, auditLogLoader:true,error: "" };
        case GET_FILTERATION_DATA_LOG_SUCCESS:
          return {
            ...state,
            loading: false,
            error: "",
            auditlog:action.payload,
            success: true,
          auditLogLoader:false,

          };
          case GET_FILTERATION_DATA_LOG_ERROR:
            return { ...state, loading: true,auditLogLoader:false, error: "" };
           
    default:
      return { ...state };
  }
};
