import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import axios from "axios";
import { servicePath } from "constants/defaultValues";
import { getAccessToken } from "helpers/Utils";
import {
  GET_DEPARTMENT,
  GET_DEPARTMENT_BY_COMPANY,
  GET_FILTERATION_DATA,
  UPDATE_DEPARTMENT,
  SAVE_DEPARTMENT,
  DELETE_DEPARTMENT,
  RESET_FILTERATION_DATA,
  GET_ALL_DEPARTMENT,
} from "../actions";

import {
  getDepartmentSuccess,
  getDepartmentError,
  getDepartmentByCompanySuccess,
  getDepartmentByCompanymentError,
  saveDepartmentError,
  saveDepartmentSuccess,
  updateDepartmentError,
  updateDepartmentSuccess,
  deleteDepartmentSuccess,
  deleteDepartmentError,
  getDepartmentByIdSuccess,
  getDepartmentByIdError,
  getFilternationDataSuccess,
  filterResetSuccess,
  getFilternationDataError,
  getAllDepartmentsError,
  getAllDepartmentsSuccess,
} from "./actions";
import SimpleAlert from "components/common/Swal Alert";
import { getIdfromToken } from "helpers/Utils";
import instance from "redux/instance/instanceApi";


export function* watchGetAllDepartment() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(GET_ALL_DEPARTMENT, getAllDepartmentss);
}
function* getAllDepartmentss() {
  // const { history } = payload;

  try {
    const departments = yield call(getAllDepartmentssAsync);
    if (!departments?.message) {
      yield put(getAllDepartmentsSuccess(departments?.departments));
    } else {
      yield put(getAllDepartmentsError(departments?.message));
    }
  } catch (error) {
    yield put(getAllDepartmentsError(error));
  }
}

const getAllDepartmentssAsync = async () => {
  try {
    
    let response = await instance.get(`/alldepartments`);

    return response.data;
  } catch (error) {
    // console.log("response.data eroooor",error?.response);
      return error?.response?.data


  }
};

export function* watchGetDepartment() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(GET_DEPARTMENT, getDepartment);
}

function* getDepartment({ payload }) {
  const { id } = payload;
  try {
    const department = yield call(getDepartmentAsync, id);

    if (!department?.message) {
      yield put(getDepartmentSuccess(department));
    } else {
      yield put(getDepartmentError(department?.message));
    }
  } catch (error) {
    yield put(getDepartmentError(error));
  }
}

const getDepartmentAsync = async (id) => {
  try {
    // let response = await axios.get(servicePath + "/departments/" + id, {
    //   headers: {
    //     "x-access-token": getAccessToken(),
    //   },
    // });
    let response = await instance.get( "/departments/" + id);

    return response.data;
  } catch (error) {}
};

export function* watchGetDepartmentByCompany() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(GET_DEPARTMENT_BY_COMPANY, getDepartmentByCompany);
}

function* getDepartmentByCompany({ payload }) {
  const { id } = payload;

  try {
    const departments = yield call(getDepartmentByCompanyAsync, payload);

    if (!departments?.message) {
      yield put(getDepartmentByCompanySuccess(departments.departments));

    } else {

      yield put(getDepartmentByCompanymentError(departments?.message));
    }
  } catch (error) {

    yield put(getDepartmentByCompanymentError(error));
  }
}

const getDepartmentByCompanyAsync = async (payload) => {
  const { id } = payload;
  try {
    const currentUser = getIdfromToken();
    // let response = await axios.get(
    //   servicePath + `/departments?${currentUser?._roleId === 4 ? `id=${id}` : `companyId=${id}`}`,
    //   {
    //     headers: {
    //       "x-access-token": getAccessToken(),
    //     },
    //   }
    // );
    let response = await instance.get( `/departments?${currentUser?._roleId === 4 ? `id=${id}` : `companyId=${id}`}`);

    return response.data;
  } catch (error) {
    // console.log("response response getDepartmentByCompanyAsync",error?.esponse)
  }
};

export function* watchSaveDepartment() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(SAVE_DEPARTMENT, saveDepartment);
}

function* saveDepartment({ payload }) {
  const { department ,companyId} = payload;
  try {
    const response = yield call(saveDepartmentAsync, department);
    const actionName = "Add Department";

    if (!response?.error) {
      const responseAudit = yield call(
        createAuditLogAsync,
        response,
        actionName,
        companyId
      );

      yield put(saveDepartmentSuccess(response.message));
      SimpleAlert("success", "Department added successfully");
    } else {
      yield put(saveDepartmentError(response.message));
    }
  } catch (error) {}
}

const saveDepartmentAsync = async (department) => {
  try {
   
    let response = await instance.post( "/departments",
    { ...department });

    return response.data;
  } catch (error) {
    console.log("error", error);
  }
};

export function* watchUpdateDepartment() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(UPDATE_DEPARTMENT, updateDepartment);
}

function* updateDepartment({ payload }) {
  const { department, dep_id,companyId } = payload;
  try {
    const actionName = "Update Department";

    const response = yield call(updateDepartmentAsync, department, dep_id);

    if (!response?.error) {
      const responseAudit = yield call(
        createAuditLogAsync,
        response,
        actionName,
        companyId
      );

      yield put(updateDepartmentSuccess(response.message));
      SimpleAlert("success", "Department updated successfully");
    } else {
      yield put(updateDepartmentError(response.message));
    }
  } catch (error) {}
}

const updateDepartmentAsync = async (department, dep_id) => {
  try {
    
    let response = await instance.post("/departments/" + dep_id,
    { ...department });
    return response.data;
  } catch (error) {
    console.log("error", error);
  }
};

export function* watchDeleteDepartment() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(DELETE_DEPARTMENT, deleteDepartment);
}

function* deleteDepartment({ payload }) {
  const { id ,companyId} = payload;
  try {
    const actionName = "delete  Department";
    const response = yield call(deleteDepartmentAsync, id);

    const department = {
      ...response,
      id: id,
    };
    if (response?.message) {

      const responseAudit = yield call(
        createAuditLogAsync,
        department,
        actionName,companyId
      );

      yield put(deleteDepartmentSuccess(id));
      SimpleAlert("success", "Department Deleted successfully");
    } else {

      yield put(deleteDepartmentError(response?.data));
      SimpleAlert("error", response?.data);
    }
  } catch (error) {

    yield put(deleteDepartmentError(error));
  }
}

const deleteDepartmentAsync = async (id) => {
  try {

    
    let response = await instance.delete("/departments/" + id);
    return response.data;
  } catch (error) {
    const err = error.response ? error.response : error.response?.data?.message ? error.response?.data?.message : error.response?.data;
    // Alert
    SimpleAlert("error",`${err}`)
    // return error.response;
  }
};

export function* watchGetFilternationDataDepartments() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(GET_FILTERATION_DATA, getfilternationDepartmentByCompany);
}

function* getfilternationDepartmentByCompany({ payload }) {
  try {
    const departments = yield call(
      getFilternationDepartmentByCompanyAsync,
      payload
    );

    if (!departments?.message) {
      // yield put(getDepartmentByCompanySuccess(departments.departments));
      yield put(getFilternationDataSuccess(departments.departments));
    } else {
      yield put(getFilternationDataError(departments?.message));
    }
  } catch (error) {
    yield put(getFilternationDataError(error));
  }
}

const getFilternationDepartmentByCompanyAsync = async (payload) => {
  try {
    const short_code = payload?.filterName?.short_code;
    const name = payload?.filterName?.name;
    const inchargeId = payload?.filterName?.inchargeId;
   
    let response = await instance.get(`/departments?short_code=${short_code}&name=${name}&inchargeId=${inchargeId} `);

    return response.data;
  } catch (error) {}
};

export function* watchResetFilters() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(RESET_FILTERATION_DATA, getResetFiltersFunct);
}

function* getResetFiltersFunct({ payload }) {
  try {
    yield put(filterResetSuccess(payload?.departments));
  } catch (error) {
    // yield put(getFilternationDataError(error));
    SimpleAlert("error", error);
  }
}

const createAuditLogAsync = async (department, actionName,companyId) => {
  const userdata = getIdfromToken()
  try {
    let audit_logs = {
      action_name: actionName,
      userId: userdata?._id,
      departmentId: department?.id,
      description: actionName,
      companyId:companyId
    };
 
    let response = await instance.post("/audit_logs",
    { ...audit_logs });

  } catch (error) {
    console.log("error", error);
  }
};

export default function* rootSaga() {
  yield all([
    fork(watchGetDepartment),
    fork(watchGetDepartmentByCompany),
    fork(watchSaveDepartment),
    fork(watchUpdateDepartment),
    fork(watchDeleteDepartment),
    fork(watchGetFilternationDataDepartments),
    fork(watchResetFilters),
    fork(watchGetAllDepartment),

  ]);
}
