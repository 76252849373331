import { getCurrentUser } from "helpers/Utils";
import {
  GET_ROLE_FILTER,
	GET_ROLE_FILTER_SUCCESS,
	GET_ROLE_FILTER_ERROR

} from "../actions";

const INIT_STATE = {
  roles:[],

  loading: false,
  error: "",
  success: false,
  message: "",
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_ROLE_FILTER:
      return { ...state, loading: true, error: "" };
    case GET_ROLE_FILTER_SUCCESS:
      return {
        ...state,
        loading: false,
        roles: action.payload,
        error: "",
      };
    case GET_ROLE_FILTER_ERROR:
      return {
        ...state,
        loading: false,
        error: action.payload.message,
      };

   
    default:
      return { ...state };
  }
};
