import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import axios from "axios";
import { servicePath } from "constants/defaultValues";
import { getAccessToken } from "helpers/Utils";
import {
  GET_TEAMS,
  GET_TEAM_BY_ID,
  GET_FILTERATION_DATA_TEAM,
  SAVE_TEAM,
  UPDATE_TEAM_DATA,
  RESET_FILTERATION_DATA,
  DELETE_TEAM,
  GET_ALL_TEAMS,
  GET_TEAMS_BY_COMPANY,
} from "../actions";

import {
  getTeamsSuccess,
  getTeamsError,
  getTeamByIdSuccess,
  getTeamByIdError,
  getfilternationTeamsSuccess,
  getfilternationTeamsError,
  saveTeamError,
  saveTeamSuccess,
  updateTeamDataError,
  updateTeamDataSuccess,
  filterResetTeamSuccess,
  deleteTeamSuccess,
  deleteTeamError,
  getAllTeamsSuccess,
  getAllTeamsError,
  getTeamsByCompanyError,
  getTeamsByCompanySuccess,
} from "./actions";
import SimpleAlert from "components/common/Swal Alert";
import { getIdfromToken } from "helpers/Utils";
import { employeessfilterGetSinleEmployee } from "helpers/Utils";
import instance from "redux/instance/instanceApi";
import { objectToQueryString } from "helpers/Utils";


export function* watchGetTeamsByCompany() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(GET_TEAMS_BY_COMPANY, getTeamssByCompany);
}
function* getTeamssByCompany({payload}) {
  // const { history } = payload;

  try {
    const teams = yield call(getTeamsByCompanyAsync,payload);
    if (!teams?.message) {
      yield put(getTeamsByCompanySuccess(teams?.teams  ));
    } else {
      yield put(getTeamsByCompanyError(teams?.message));
    }
  } catch (error) {
    console.log("error ==================",error)
    yield put(getTeamsByCompanyError(error));
  }
}
const getTeamsByCompanyAsync = async (payload) => {
  try {
    
    
 
    let response = await instance.get(`/teams?` + objectToQueryString(payload));

    return response.data;
  } catch (error) {
    console.log("error ==========================",error?.response);
    return error?.response?.data


  }
};

export function* watchGetAllTeams() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(GET_ALL_TEAMS, getAllTeamsss);
}
function* getAllTeamsss() {
  // const { history } = payload;

  try {
    const teams = yield call(getAllTeamssAsync);
    if (!teams?.message) {
      yield put(getAllTeamsSuccess(teams.teams  ));
    } else {
      yield put(getAllTeamsError(teams?.message));
    }
  } catch (error) {
    console.log("error ==================",error)
    yield put(getAllTeamsError(error));
  }
}

const getAllTeamssAsync = async () => {
  try {
    
    
 
    let response = await instance.get(`/allteams`);

    return response.data;
  } catch (error) {
    console.log("error ===============getAllTeamssAsync===========",error?.response);
    return error?.response?.data


  }
};

export function* watchGetTeams() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(GET_TEAMS, getTeams);
}

function* getTeams({ payload }) {
  const { id } = payload;
  try {
    const teams = yield call(getTeamsAsync,payload);
    const tokenId = getIdfromToken();

      
    if (!teams.message) {
      yield put(getTeamsSuccess(teams.teams));
    } else {
      yield put(getTeamsError(teams?.message));
    }
  } catch (error) {
    yield put(getTeamsError(error));
  }
}

const getTeamsAsync = async (payload) => {
  const { id } = payload;
  try {
    // let response = await axios.get(servicePath + `/teams?companyId=${id}`, {
    //   headers: {
    //     "x-access-token": getAccessToken(),
    //   },
    // });
    let response = await instance.get( `/teams?companyId=${id}`);
    return response.data;
  } catch (error) {}
};

export function* watchGetTeamById() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(GET_TEAM_BY_ID, getTeamById);
}

function* getTeamById({ payload }) {
  const { id } = payload;
  try {
    const team = yield call(getTeamByIdAsync, id);

    if (!team.message) {
      yield put(getTeamByIdSuccess(team));
    } else {
      yield put(getTeamByIdError(team.message));
    }
  } catch (error) {
    yield put(getTeamByIdError(error));
  }
}

const getTeamByIdAsync = async (id) => {
  try {
    // let response = await axios.get(servicePath + "/teams/" + id, {
    //   headers: {
    //     "x-access-token": getAccessToken(),
    //   },
    // });
    let response = await instance.get("/teams/" + id);


    return response.data;
  } catch (error) {}
};

export function* watchSaveTeam() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(SAVE_TEAM, saveTeam);
}

function* saveTeam({ payload }) {
  const { team } = payload;
  try {
    const actionName = "Add Team";
    const response = yield call(saveTeamAsync, team);

    if (!response?.error) {
      const responseAudit = yield call(
        createAuditLogAsync,
        response,
        actionName,
        team 

      );
      yield put(saveTeamSuccess(response));
      SimpleAlert("success","Team added successfully");

    } else {
      yield put(saveTeamError(response.message));
    }
  } catch (error) {}
}

const saveTeamAsync = async (team) => {
  try {
   
    let response = await instance.post("/teams",{ ...team });

    return response.data;
  } catch (error) {
    console.log("error", error);
    const err = error.response ? error.response : error.response?.data?.message ? error.response?.data?.message : error.response?.data;
    SimpleAlert("error",`${err}`)

  }
};

export function* watchUpdateTeam() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(UPDATE_TEAM_DATA, updateTeam);
}

function* updateTeam({ payload }) {
  const { team, id } = payload;
  try {
    const actionName = "Update Team";

    const response = yield call(updateTeamsAsync, team, id);
    if (!response?.error) {
      const responseAudit = yield call(
        createAuditLogAsync,
        response,
        actionName,
        team
      );
      yield put(updateTeamDataSuccess(response.message));
      SimpleAlert("success","Team updated successfully");

    } else {
      yield put(updateTeamDataError(response.message));
    }
  } catch (error) {}
}

const updateTeamsAsync = async (team, id) => {
  try {

    let response = await instance.post("/teams/" + id,{ ...team });

    return response.data;
  } catch (error) {
    console.log("error", error);
    const err = error.response ? error.response : error.response?.data?.message ? error.response?.data?.message : error.response?.data;
    SimpleAlert("error",`${err}`)
  }
};



export function* watchDeleteTeamById() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(DELETE_TEAM, deleteTeamById);
}

function* deleteTeamById({ payload }) {
  const { id } = payload;
  try {
    const team = yield call(deleteTeamByIdAsync, id);

    if (team?.message) {
      yield put(deleteTeamSuccess(id));
      SimpleAlert("success","Team Deleted  Successfully")

    } else {
      yield put(deleteTeamError(team.message));
    }
  } catch (error) {
    yield put(deleteTeamError(error));
  }
}

const deleteTeamByIdAsync = async (id) => {
  try {
    
    let response = await instance.delete( "/teams/" + id);

    return response.data;
  } catch (error) {
    return error.response

  }
};







export function* watchGetFilternationDataTeams() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(GET_FILTERATION_DATA_TEAM, getFilternationDataTeam);
}

function* getFilternationDataTeam({ payload }) {
  try {
    const teams = yield call(getfilternationTeamsAsync, payload);

    if (!teams?.message) {
      yield put(getfilternationTeamsSuccess(teams.teams));
    } else {
      yield put(getfilternationTeamsError(teams?.message));
    }
  } catch (error) {
    yield put(getfilternationTeamsError(error));
  }
}

const getfilternationTeamsAsync = async (payload) => {
  try {
    const short_code = payload?.filterName?.short_code;
    const team_name = payload?.filterName?.name;
    const departmentId = payload?.filterName?.departmentId;

  
    let response = await instance.get( `/teams?short_code=${short_code}&name=${team_name}&departmentId=${departmentId} `);

    return response.data;
  } catch (error) {}
};

export function* watchResetFiltersTeam() {
  // eslint-disable-next-line no-use-before-define
  yield takeEvery(RESET_FILTERATION_DATA, getResetFiltersFunct);
}
function* getResetFiltersFunct({ payload }) {

  try {
      yield put(filterResetTeamSuccess(payload?.team));

  } catch (error) {
      SimpleAlert("error",error)
  }

}

const createAuditLogAsync = async (response, actionName,team) => {
  const userdata = getIdfromToken();
  const companyId = team?.companyId;

  try {
    let audit_logs = {
      action_name: actionName,
      userId: userdata?._id,
      teamId: response?.id,
      description: actionName,
      companyId:companyId
    };
   
    let response = await instance.post( "/audit_logs",
    { ...audit_logs });

  } catch (error) {
    console.log("error", error);
    const err = error.response ? error.response : error.response?.data?.message ? error.response?.data?.message : error.response?.data;
    SimpleAlert("error",`${err}`)
    
  }
};

export default function* rootSaga() {
  yield all([
    fork(watchGetTeams),
    fork(watchGetTeamsByCompany),
    fork(watchSaveTeam),

    fork(watchUpdateTeam),
    fork(watchGetTeamById),
    fork(watchDeleteTeamById),
    fork(watchGetAllTeams),
    fork(watchGetFilternationDataTeams),
    fork(watchResetFiltersTeam),

  ]);
}
