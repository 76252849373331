import { createStore, applyMiddleware, compose } from 'redux';
import { composeWithDevTools } from "redux-devtools-extension";

import createSagaMiddleware from 'redux-saga';
// import reducers from './reducers';
import rootreducer from './reducers';

import sagas from './sagas';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage' 
const sagaMiddleware = createSagaMiddleware();

const middlewares = [sagaMiddleware];
const persistConfig = {
	key: 'root',
	storage,
  }
const configureStore = () => {
	const store = createStore(
		rootreducer,
		// reducers,
		// compose(applyMiddleware(...middlewares)),
		composeWithDevTools(applyMiddleware(...middlewares)),
	);

	sagaMiddleware.run(sagas);
	return store;
	// const persistedReducer = persistReducer(persistConfig, reducers)
	// const store = createStore(
	// 	persistedReducer,
	// 	// compose(applyMiddleware(...middlewares)),
	// 	composeWithDevTools(applyMiddleware(...middlewares)),
	// );
	// const persistor = persistStore(store)

	// // kick off root saga
	// sagaMiddleware.run(sagas)

	// return { store, persistor };
};
const store = configureStore();
 
// const confiqStore = configureStore();
// const store = confiqStore.store;
// export const persistor =  confiqStore.persistor;

export default store;
