import { all, call, fork, put, takeEvery } from "redux-saga/effects";
import axios from "axios";
import { servicePath } from "constants/defaultValues";
import { getAccessToken } from "helpers/Utils";
import {
    GET_ROLE_FILTER
} from "../actions";

import {
    getRoleFilterSuccess,
    getRoleFilterError,
 
} from "./actions";
import instance from "redux/instance/instanceApi";

export function* watchGetAllRoles() {
    // eslint-disable-next-line no-use-before-define
    yield takeEvery(GET_ROLE_FILTER, getAllRoles);
}

function* getAllRoles() {
    // const { history } = payload;
    try {
        const allroles = yield call(getAllRolesAsync);
        if (!allroles?.message) {
            yield put(getRoleFilterSuccess(allroles?.roles
                ));
        } else {
            yield put(getRoleFilterError(allroles?.message));
        }
    } catch (error) {
        yield put(getRoleFilterError(error));
    }
}

const getAllRolesAsync = async () => {

    try {
        // let response = await axios.get(servicePath + '/roles/filter', {
        //     headers: {
        //         'x-access-token': getAccessToken()
        //     }
        // });
        let response = await instance.get( '/roles/filter');
        return response.data;
    } catch (error) {

    }
};




export default function* rootSaga() {
    yield all([
        fork(watchGetAllRoles),

     

    ]);
}
