import {
	GET_ROLE_FILTER,
	GET_ROLE_FILTER_SUCCESS,
	GET_ROLE_FILTER_ERROR
	

} from "../actions";

export const getRoleFilter = () => ({
	type: GET_ROLE_FILTER,
});



export const getRoleFilterSuccess = (roles) => ({
	type: GET_ROLE_FILTER_SUCCESS,
	payload: roles,
});


export const getRoleFilterError = (message) => ({
	type: GET_ROLE_FILTER_ERROR,
	payload: { message },
})


